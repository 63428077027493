import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import ModalReferral from './modalReferral';
// import $ from "jquery";

import Logo from "../Assets/Images/logo.svg";
// import Phone from "../Assets/Images/phone.svg";
// import gold_banner from "../Assets/Images/new-gold-banner-special.png";
import promotion from "../Assets/Images/promotionagm.png";


import Modal from './ModalPromo';

// const SocialMenu = React.lazy(() => import("./FooterParts/SocialMenu"));

const SocialMenuMobile = React.lazy(() =>
  import("./FooterParts/SocialMenuMobile")
);
// const ReferralModal = () => {
//   const timeBannerShown = 7000;
//   const [isDialogOpen, setDialogOpen] = React.useState(false);

//   React.useEffect(() => {
//     if (!window.location.pathname.match("referrals") && !sessionStorage.bannerSkipped) {
//       setTimeout( () => {

//         if (!window.location.pathname.match("referrals"))
//           setDialogOpen(true);

//       }, timeBannerShown );
//     }
//   }, []);

//   return (
//     <ModalReferral  open={isDialogOpen} handleClose={() => { sessionStorage.setItem('bannerSkipped', true); setDialogOpen(false); }} />
//   );

// };

/* eslint-disable */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      menuOpen: false,
      viewStickyQuoteButton: false,
      headerHeight: 0,
      isModalPromoShow: false
    };
    this.handler = {};
  }

  static propTypes = {};

  componentDidMount() {
    //this.checkHeaderHeight();
    //window.addEventListener("resize", this.checkHeaderHeight);
    // Storing source in session for later use
    let vars = {};
    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
        if (localStorage) {
          localStorage.setItem("vars", JSON.stringify(vars));
        }
      }
    );

    if ("agmsource" in vars) {
      sessionStorage.setItem("agmsource", vars.agmsource);
    }

    document.addEventListener("scroll", () => {
      let isVisible = window.scrollY < 50 ? false : true;
      this.setState({ viewStickyQuoteButton: isVisible });
      try {
        if (
          document.getElementsByClassName("home-banner-button-mobile").length >
          0
        ) {
          document.getElementsByClassName(
            "home-banner-button-mobile"
          )[0].style.display = isVisible ? "none" : "block";
        }
      } catch (e) {
        console.log("e", e);
      }
    });
    window.scrollTo(0, 0);

    window.handlePromoPopUp = this.handlePromoPopUp;
  }


  mobileMenuClick = (isvisible) => {
    this.setState({
      menuOpen: isvisible === false ? false : !this.state.menuOpen,
    });
  };


  handlePromoPopUp = () => {
    this.handler.updateState();
  }
  
  
  render() {
    // console.log('render Header')
    // console.log(this.props)
    let phone =
      this.props.data == null ? "416-477-5429" : this.props.data.phone;

    let phHTML = (
      <span>
        <a href={"tel:" + phone}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M476.5 22.9L382.3 1.2c-21.6-5-43.6 6.2-52.3 26.6l-43.5 101.5c-8 18.6-2.6 40.6 13.1 53.4l40 32.7C311 267.8 267.8 311 215.4 339.5l-32.7-40c-12.8-15.7-34.8-21.1-53.4-13.1L27.7 329.9c-20.4 8.7-31.5 30.7-26.6 52.3l21.7 94.2c4.8 20.9 23.2 35.5 44.6 35.5C312.3 512 512 313.7 512 67.5c0-21.4-14.6-39.8-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3C463 286.9 286.9 463 69.3 464z" />
          </svg>
          <span style={{ fontSize: '23px', textShadow: '1px 1px 2px rgba(0,0,0,.3)' }} className="call-text">{phone}</span>
        </a>
        <Link style={{ marginLeft: '15px' }} className="btn red-btn-2 hvr-sweep-to-right" to="/estimate">FREE CONSULTATION</Link>
      </span>
    );
    return (
      <>
        <Helmet>
          {/* <script defer
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0&callback=initMap">      
          </script> */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-952060088"
          ></script>
          <script>
            {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-952060088');
                    `}
          </script>
          <script>
            {`
                        window.addEventListener('load', function(){
                            window.addEventListener('click', function(e){
                                if(e.target.matches('[href="tel:416-477-5429"]')){
                                    gtag('event', 'conversion', {'send_to': 'AW-952060088/EVffCNfIgMQBELiR_cUD'});
                                }
                            })
                        });
                    `}
          </script>
        </Helmet>
        <section
          className="header-section"
          id="main-header"
          ref={(headerEL) => {
            this.headerEL = headerEL;
          }}
        >
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="AGM Logo" />
            </Link>
          </div>

          {/* <div className="gold_banner hvr-curl-top-left" to="/promotion-agm">
            <div onClick={this.handlePromoPopUp}>
                <img src={promotion} alt="" />
            </div>
          </div> */}

          <div className="main-nav">
            <div className="logo-2">
              <Link to="/">
                <img src={Logo} alt="AGM Logo" />
              </Link>
            </div>

            <div className="promotion-mobile" style={{ visibility: 'hidden', pointerEvents: 'none' }} to="/promotion-agm">
              <div 
                onClick={this.handlePromoPopUp} 
              >
                  <img src={promotion} alt="AGM Promo" />
              </div>
            </div>
            {/* <div className="header-call-us mobile-none"> {phHTML} </div> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={this.mobileMenuClick}
              className="mobile-menu-icon"
              viewBox="0 0 448 512"
            >
              <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
            </svg>
            <div className="header-call-us mobile-none"> {phHTML} </div>
            <ul className={this.state.menuOpen ? "open" : null}>
              <div
                className="close-menu display-mobile desktop-none"
                onClick={this.mobileMenuClick}
              >
                <span>X</span>
              </div>
              <li className="shift">
                <ol>
                  <li className="desktop-none">
                    <Link
                      to="/"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Home
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/wet-basement-underpining"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      WET BASEMENT & UNDERPINNING
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/the-agm-difference"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Our Process
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/careers"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Careers
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/our-clients"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Our Clients
                    </Link>
                  </li>
                  {sessionStorage.getItem("countryCode") === "CA" && (
                    <>
                      <hr></hr>
                      <li>
                        <Link
                          to="/financing"
                          onClick={this.mobileMenuClick.bind(this, false)}
                        >
                          Financing
                        </Link>
                      </li>
                    </>
                  )}
                  <hr></hr>
                  <li className="mobile-none">
                    <Link
                      to="/our-work"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Our Work
                    </Link>
                  </li>
                  <hr className="mobile-none"></hr>
                  <li className="desktop-none">
                    <Link
                      to="/our-work"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Our Work
                    </Link>
                  </li>
                  <hr></hr>
                  <li className="mobile-none">
                    <Link
                      to="/visualizer"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Visualizer
                    </Link>
                  </li>
                  <hr className="mobile-none"></hr>
                  <li className="desktop-none">
                    <Link
                      to="/visualizer"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Visualizer
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/why-agm"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      Why AGM
                    </Link>
                  </li>
                  <hr></hr>
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={this.mobileMenuClick.bind(this, false)}
                    >
                      About Us
                    </Link>
                  </li>
                </ol>
                <div className="desktop-none button-mobile display-mobile quote-container">
                  <Link
                    to="/estimate"
                    className="btn red-btn"
                    onClick={this.mobileMenuClick.bind(this, false)}
                  >
                    GET A FREE QUOTE!
                  </Link>
                </div>
                <div className="desktop-none">
                  <SocialMenuMobile />
                </div>
              </li>
            </ul>

          </div>
          {/* <div className="free-consultation-btn hvr-grow">
            <Link to="/estimate" className="btn light-blue-sq-btn">
              Free Consultation
            </Link>
          </div> */}
        </section>

        <div
          className={"iPad-display"}
          style={{ height: this.state.headerHeight }}
        ></div>


        <div
          className={
            "button-mobile display-mobile " +
            (this.state.viewStickyQuoteButton ? "" : "sticky-quote-container")
          }
          style={{
            position: "fixed",
            top: "0px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 999,
            width: "100%",
          }}
        >
          <Link
            to="/estimate"
            className="btn red-btn"
            style={{
              width: "100%",
            }}
          >
            Get a Free Quote!
          </Link>
        </div>

        <Modal handler={this.handler} />
         {/* <ReferralModal/> */}
      </>
    );
  }
}

export default Header;
